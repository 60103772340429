.colorful-boxes{
    .box{
        padding: 30px;
        line-height: 1.3;
        color: $feher;
        box-shadow: none;
        @media(max-width: $screen-xs-max){
            margin: 0 -15px;
            box-shadow: 0 4px rgba(0,0,0,0.05) inset;
        }
        h2{
            font-size: 20px;
            font-family: $condensed;
            text-transform: uppercase;
            padding: 0 0 20px 0;
            text-align: center;
            width: 100%;
            font-weight: 600;
            margin: 0;
            border-bottom: 1px solid $feher;
        }
        p{
            padding: 20px 0 0 0;
            margin: 0 0 0 0;
        }
        a.button{
            color: $feher;
            background: $narancs;
            padding: 10px 20px;
            text-align: center;
            width: 100%;
            font-family: $condensed;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 18px;
            display: block;
            margin-top: 20px;
            &:hover{
                background: #e09c3f;
                text-decoration: none;
            }
        }
    }
    &:first-child{
        .box{
            background: $babakek;
        }
    }
    &:nth-child(2){
        .box{
            background: $arany;
        }
    }
    &:nth-child(3){
        .box{
            @media(max-width: $screen-md-max){
                margin-top: 0;
            }
            @media(max-width: $screen-sm-max){
                margin-top: $grid-gutter-width;
            }
            @media(max-width: $screen-xs-max){
                margin-top: 0;
            }
            background: $zold;
        }
    }
}