// Glyphicons font path
@import "http://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700|Open+Sans:400italic,300,600,400&subset=latin,latin-ext";
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;

$babakek: #3dbaec;
$narancs: #f8ab46;
$arany: #ecc93d;
$zold: #41d67d;
$sotetkek: #2c4a64;
$feher: #fff;
$szurke: #494848;

//fonts
$condensed: 'Open Sans Condensed', sans-serif;
$normal: 'Open Sans', sans-serif;