footer.content-info{
    margin-top: 60px;
    background: $sotetkek;
    padding: 20px 0;
    width: 100%;
    @media(max-width: $screen-xs-max){
        margin-top: 0;
    }
    .footer-logo{
        opacity: 0.6;
        float: left;
        img{
            float: left;
            max-height: 60px;
            margin-right: 10px;
        }
    }
    span.slogan{
        line-height: 1.6;
        display: block;
        //        float: left;
        color: $feher;
        font-weight: 600;
        @media(max-width: $screen-md-max){
            //    width: 36%;
            //    padding: 25px 20px;
        }
        @media(max-width: $screen-sm-max){
            width: 68%;
            padding: 35px 20px;
        }
        @media(max-width: $screen-xs-max){
            width: 55%;
            padding: 15px 20px;
        }
        /*
        25px
        15px
        */
    }
    .contact {
        opacity: 0.6;
        color: white;
        h3 {
            margin-top: 4px;
        }
        p {
            margin-bottom: 10px;
            font-weight: bold;
            line-height: 1.2;
            span {
                min-width: 60px;
                display: inline-block;
            }
        }
        a {
            color: white;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
    .data {
        opacity: 0.6;
        color: white;
        h3 {
            margin-top: 4px;
        }
        p {
            margin-bottom: 10px;
            line-height: 1.2;
            font-weight: bold;
        }
        a {
            color: white;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
}