// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
  @media(max-width: $screen-xs-max){
      margin: 30px 0;
      body.home &{
          margin: 0;
      }
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}
