header.banner{
    padding-top: 20px;
    min-height: 80vh;
    margin-bottom: 60px;
    @media(max-width: $screen-xs-max){
        margin-bottom: 0;
        min-height: 30vh;
    }
	.container {
		@media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
			width: auto;
		}
	}
    &.narrow{
        min-height: 20vh;
    }
    span.slogan{
        padding: 10px 20px;
        display: block;
        float: left;
        color: $feher;
        font-weight: 600;
        max-width: 200px;
        line-height: 1.2;
        @media(max-width: $screen-md-max){
            width: 36%;
            padding: 25px 20px;
            //sdsd
        }
        @media(max-width: $screen-sm-max){
            width: 68%;
            padding: 35px 20px;
        }
        @media(max-width: $screen-xs-max){
            width: 55%;
            padding: 15px 20px;
        }
    }
    .brand{
        float: left;
    }
	.menu-item-has-children {
		@media (min-width: $screen-md-min) {
			margin-top: -20px;
			padding-top: 20px;
		}
		&:hover, focus {
			background-color: #f8ab46 !important;
			a {
				background-color: #f8ab46 !important;
				color: #fff !important;
				&:hover, &:focus {
					color: #fff !important;
				}
			}
		}
	}
    .highlighted-box{
        margin: 60px 0 60px 60px;
        border: 2px solid $feher;
        padding: 20px;
        display: table;
        background: rgba(0,0,0,0.2);
        color: $feher;
        @media(max-width: $screen-sm-max){
            margin: 60px;
        }
        @media(max-width: $screen-xs-max){
            margin: 60px 0;
        }
        h1{
            font-size: 20px;
            font-family: "Open Sans Condensed",sans-serif;
            text-transform: uppercase;
            padding: 0 0 20px 0;
            width: 100%;
            font-weight: 600;
            margin: 0;
            border-bottom: 1px solid #fff;
        }
        p{
            margin: 10px 0 0 0;
            font-weight: 600;
        }
        .button{
            color: $feher;
            background: $narancs;
            padding: 10px 40px;
            text-align: center;
            float: left;
            font-family: $condensed;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 18px;
            display: block;
            &:hover{
                background: #e09c3f;
                text-decoration: none;
            }
        }
    }
}
nav{
	.navbar-nav {
		margin: 0 -15px;
	}
    .nav{
		float: right;
        @media(max-width: $screen-sm-max){
       /*     width: 100%;
            display: none;
            position: absolute;
            z-index: 50;
            top: 0;
            padding: 82px 0;
            left: 0;*/
            background: $sotetkek;
			float: none;
        }
        li.menu-item{
            float: left;
            @media(max-width: $screen-sm-max){
                width: 100%;
                text-align: center;
                a{
                    padding: 15px 20px;
                }
            }
            a{
                display: block;
                color: $feher;
                font-weight: 600;
                text-transform: uppercase;
                font-family: $condensed;
                padding: 10px 20px;
                &:hover{
                    color: $feher;
                    background: $narancs;
                }
            }
        }
    }
}

#hamburger{
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000;
    display: block;
    span.bar{
        width: 24px;
        height: 4px;
        margin-bottom: 4px;
        border-radius: 2px;
        display: block;
        background: $feher;
    }
}

.sub-menu{
    display: none;
    position: absolute;
    //sdsdsdsdfdssd
    li{
        float: left;
    }
}

@media (min-width: $screen-md-min) {
	.dropdown-menu {
		border: 0;
		padding-top: 0;
		padding-bottom: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		li {
			width: 100%;
			background-color: #F8AB46;
			a {
				width: 88%;
				padding-left: 0 !important;
				padding-right: 10px !important;
				padding-bottom: 3px !important;
				padding-top: 17px !important;
				margin-left: 10px;
				border-bottom: 1px solid #F8AB46;
				&:hover, &:focus {
					border-bottom: 1px solid white;
				}
			}
		}
	}
}
.navbar-default {
	.navbar-toggle {
		border-color: transparent;
		&:hover, &:focus {
			background-color: transparent;
		}
		.icon-bar {
		    width: 24px;
			height: 4px;
			margin-bottom: 4px;
			border-radius: 2px;
			display: block;
			background: #fff;
		}
	}
	.navbar-nav  {
		&>.open {
			.dropdown-menu {
				&>li {
					&> a {
						color: white;
						&:hover, &:focus {
							color: white;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	.navbar-header {
		float: none;
	}
}

.dropdown-menu {
	right: 0;
	padding: 0 0;
	background-color: #F8AB46;
}

.collapse {
	display: none;
	&.in {
		display: block;
	}
}