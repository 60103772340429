body{
    font-family: $normal;
    font-size: 16px;
    line-height: 1.8;
}

h1,h2,h3,h4,h5,h6{
    font-family: $condensed;
}

.ord_number{
    padding: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 44px;
    font-size: 24px;
    font-weight: 600;
    margin-right: 10px;
    float: left;
    color: $babakek;
    border: 3px solid $babakek;
}

.gform_wrapper{
    ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

.gfield{
    clear: both;
    padding-bottom: 60px;
    display: table;
    width: 100%;
    div{
        label{
            font-family: $condensed;
        }
    }
    p{
        margin-left: 60px;
    }
}
.gfield_radio,.gfield_checkbox{
    li{
        float: left;
        label{
            height: 58px;
            line-height: 69px;
            padding-right: 20px;
            font-weight: 500;
            font-family: $normal;
        }
    }
}

.gfield_error{
    input[type=text],input[type=number],textarea,select,checkbox{
        border: 3px solid $narancs;
    }
}

.gform_confirmation_wrapper{
    min-height: 90vh;
}

.gform_footer{
    padding: 40px 0;
    display: table;
    width: 100%;
}

input[type=text],input[type=number],textarea,select,checkbox{
    border: 3px solid $szurke;
    padding: 10px;
    float: left;
}

.ginput_container{
    select{
        float: left;
        margin-right: 20px;
    }
}

input[type=text],input[type=number],textarea{
    width: 100%;
}

.gform_button{
    border: 4px solid $babakek;
    width: 100%;
    display: table;
    background: none;
    padding: 6px 20px;
    font-family: $condensed;
    color: $babakek;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    &:hover{
        background: $babakek;
        color: $feher;
    }
}

span.checkbox{
    width: 50px;
    height: 50px;
    line-height: 45px;
    font-size: 20px;
    display: block;
    text-align: center;
    font-size: 28px;
    float: left;
    margin-right: 20px;
    border: 3px solid $szurke;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: context-menu;
}

