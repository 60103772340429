h1,h2{
    padding: 0 0 30px 0;
    margin: 0;
    font-family: $condensed;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
}

.well{
    border: 4px solid $babakek !important;
    border-radius: 0;
    background: none;
    font-size: 18px;
    box-shadow: none;
    margin-bottom: 30px;
    font-family: $condensed;
    font-weight: 600;
    color: $szurke;
    h2{
        color: $babakek;
        padding: 0 0 6px 0;
    }
    a{
        color: $narancs;
        text-decoration: none;
        &:focus,&:active,&:visited{
            text-decoration: none;
            color: $narancs;
        }
        &:hover{
            text-decoration: none;
            color: $babakek;
        }
    }
    p{
        margin: 0;
    }
}

p{
    margin-bottom: 30px;
}

.page-header{
    margin: 0;
    padding: 0;
    border-bottom: 0;
}

a.full-width-button{
    width: 100%;
    padding: 6px 30px;
    font-weight: 600;
    text-align: center;
    border: 4px solid $narancs;
    font-family: $condensed;
    font-size: 22px;
    color: $narancs;
    display: table;
    text-decoration: none;
    text-transform: uppercase;
    &:focus,&:active,&:visited{
        text-decoration: none;
        color: $narancs;
    }
    &:hover{
        color: $feher;
        background: $narancs;
    }
}

h3{
    font-weight: 600;
    font-size: 16px;
    font-family: $normal;
}

button#calculate{
    width: 100%;
    padding: 6px 30px;
    font-weight: 600;
    text-align: center;
    border: 4px solid $narancs;
    font-family: $condensed;
    background: none;
    font-size: 22px;
    color: $narancs;
    display: table;
    text-decoration: none;
    text-transform: uppercase;
    &:focus,&:active,&:visited{
        text-decoration: none;
        color: $narancs;
    }
    &:hover{
        color: $feher;
        background: $narancs;
    }
}