@import "common/_variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/_bootstrap.scss";
// endbower

@import "common/_global";
@import "components/_buttons";
@import "components/_comments";
@import "components/_forms";
@import "components/_grid";
@import "components/_wp-classes";
@import "layouts/_header";
@import "layouts/_sidebar";
@import "layouts/_footer";
@import "layouts/_pages";
@import "layouts/_posts";
@import "layouts/_home";


.error {
      border-color: #a94442 !important;
}

.result-box {
    margin-bottom: 60px !important;
}
//sd